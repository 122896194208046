export const ROUTES = {
  index: { main: "/", container: "*" },
  authorization: { path: "/authorization", link: "/authorization" },
  page: { path: "/white-page", link: "/white-page" },
  showWhitePage: { path: "/white-page/:id", link: "/white-page/:id" },
};

export const loginRole = (role) => {
  switch (role) {
    case 'sysadmin':
      return 'sysadmin';
    case 'helper':
      return 'helper';
    case 'farmer':
      return 'farmer';
    case 'buyer':
      return 'buyer';
    case 'admin-123456789':
      return 'admin';
    case 'webmaster':
      return 'webmaster';
    case 'superadmin':
      return 'superadmin';
    default:
      return 'error';
  }
};
