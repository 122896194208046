import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import { useEffect, useState } from "react";
import SelectSearch from "../UI/Select";
import { optionsTypePage } from "../../helpers/options";
import { useDispatch, useSelector } from "react-redux";
import SelectSearchMulti from "../UI/SelectIsMulti";
import {
  createWhitePage,
  generatePrompt,
  getBtnColors,
  getFooterColors,
  getHeaderColors, getOtherColors, regenerateWhitePage
} from "../../pages/WhitePage/action";
import "./styles.scss";
import UIFormTextaria from "../UI/UIFormTextaria";
import UIFormInput from "../UI/UIFormInput";

const GeneratorWhitePage = ({edit, closeModal}) => {
  const themes = useSelector((state) => state.whitePageReducer.themes);
  const dalle = useSelector((state) => state.whitePageReducer.dalle);
  const locales = useSelector((state) => state.whitePageReducer.locales);
  const gptModels = useSelector((state) => state.whitePageReducer.gptModels);

  const [headersColors, setHeadersColors] = useState([]);
  const [footerColors, setFooterColors] = useState([]);
  const [btnColors, setBtnColors] = useState([]);
  const [otherColors, setOtherColors] = useState([]);

  const [commonData, setCommonData] = useState({
    iframe_src: "",
    currency_label: '',
    goods_quantity: 3,
    posts_quantity: 3
  })

  const [build, setBuild] = useState({
    name: "",
    theme: null,
    topic: "",
    domain_name: "",
    site_type: null,
    locale: null,
    available_locales: [],
    chat_gpt_model: null,
    dalle_model: "dall-e-3",
    use_ai: true,
    tag_id: "",
    has_shop: false,
    has_blog: false,
    has_services: false,
    has_iframe: false,
    theme_color: {
      header: null,
      footer: null,
      first_btn: null,
      second_btn: null,
      other_elements: null,
    },
    contacts_information: {
      addresses: {},
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);

  const dispatch = useDispatch();

  const handleChangeInput = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setBuild({ ...build, [key]: value });
  };


  const handleChangeInputCommonData = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setCommonData({ ...commonData, [key]: value });
  };

  const handleChangeInputAddress = (e) => {
    const value = e.target.value;
    const key = e.target.id;

    setBuild({
      ...build,
      contacts_information: {
        ...build.contacts_information,
        addresses: { ...build.contacts_information.addresses, [key]: value },
      },
    });
  };

  const handleChangeSelect = (item, id) => {
    if (id === "locale") {
      setBuild({ ...build, available_locales: [item], [id]: item });
      return;
    }
    setBuild({ ...build, [id]: item });
  };


  const handleChangeSelectColors = (item, id) => {
    setBuild({ ...build, theme_color: {...build.theme_color, [id]: item} });
  };

  const randomizer = async () => {
    if (themes.length > 0) {
      const randomIndex = Math.floor(Math.random() * themes.length);
      const randomTheme = themes[randomIndex];
      await setBuild({ ...build, theme: randomTheme.value });
    }

  };

  useEffect(() => {
    if(!edit) {
      randomizer();
    }
    if (edit) {
      const builded = {
        name: edit?.name,
        theme: edit?.theme,
        topic: edit?.topic,
        domain_name: edit?.domain_name,
        site_type: edit?.site_type,
        locale: edit?.locale,
        available_locales: edit?.available_locales,
        chat_gpt_model: null,
        dalle_model: "dall-e-3",
        use_ai: true,
        tag_id: edit?.tag_id,
        has_shop: edit?.has_shop,
        has_blog: edit?.has_blog,
        has_services: edit?.has_services,
        has_iframe: edit?.has_iframe,
        theme_color: {
          header: edit.theme_color.header,
          footer: edit?.theme_color?.footer,
          first_btn: edit?.theme_color?.first_btn,
          second_btn: edit?.theme_color?.second_btn,
          other_elements: edit?.theme_color?.other_elements,
        },
        contacts_information: {
          addresses:  edit?.contacts_information?.addresses
        },
      };
      setCommonData(edit?.common_data);

      setBuild({...build, ...builded});
    }
  }, [themes]);

  const loadDataColors = async () => {
    if(build.theme !== null) {
      if(!edit) {
        setBuild(prevBuild => ({
          ...prevBuild,
          theme_color: {
            header: null,
            footer: null,
            first_btn: null,
            second_btn: null,
            other_elements: null,
          }
        }));
      }

      const header = await dispatch(getHeaderColors(build.theme));
      const btn = await dispatch(getBtnColors(build.theme));
      const footer = await dispatch(getFooterColors(build.theme));
      const other = await dispatch(getOtherColors(build.theme));

      if(!edit) {

        if (header) {
          const randomIndex = Math.floor(Math.random() * header.length);
          const randomTheme = header[randomIndex];
          setBuild(prevBuild => ({
            ...prevBuild,
            theme_color: {
              ...prevBuild.theme_color,
              header: randomTheme.value,
            },
          }));
        }

        if (footer) {
          const randomIndex = Math.floor(Math.random() * footer.length);
          const randomTheme = footer[randomIndex];
          setBuild(prevBuild => ({
            ...prevBuild,
            theme_color: {
              ...prevBuild.theme_color,
              footer: randomTheme.value,
            },
          }));
        }

        if (btn) {
          const randomIndex = Math.floor(Math.random() * btn.length);
          const randomTheme = btn[randomIndex];
          setBuild(prevBuild => ({
            ...prevBuild,
            theme_color: {
              ...prevBuild.theme_color,
              first_btn: randomTheme.value,
            },
          }));
        }

        if (btn) {
          const randomIndex = Math.floor(Math.random() * btn.length);
          const randomTheme = btn[randomIndex];
          setBuild(prevBuild => ({
            ...prevBuild,
            theme_color: {
              ...prevBuild.theme_color,
              second_btn: randomTheme.value,
            },
          }));
        }

        if (other) {
          const randomIndex = Math.floor(Math.random() * other.length);
          const randomTheme = other[randomIndex];
          setBuild(prevBuild => ({
            ...prevBuild,
            theme_color: {
              ...prevBuild.theme_color,
              other_elements: randomTheme.value,
            },
          }));
        }

      }

      setHeadersColors(header);
      setBtnColors(btn);
      setFooterColors(footer);
      setOtherColors(other);
    }
  };



  useEffect(() => {
    loadDataColors();
  }, [build.theme]);

  const handleClickButton = async () => {


    const options = {
      ...build,
    }


    if(build?.has_iframe) {
      options.common_data = {...options.common_data, iframe_src: commonData.iframe_src}
    }

    if(build?.has_shop) {
      options.common_data = {...options.common_data, currency_label: commonData.currency_label, goods_quantity: commonData.goods_quantity}
    }

    if(build?.has_blog) {
      options.common_data = {...options.common_data, posts_quantity: commonData.posts_quantity}
    }

    if(edit) {
      setIsLoading(true);
      await dispatch(regenerateWhitePage (edit.id, options));

      setIsLoading(false);
      setCommonData({
        iframe_src: "",
        currency_label: '',
        goods_quantity: 3,
        posts_quantity: 3
      })

      setBuild({
        name: "",
        theme: null,
        topic: "",
        domain_name: "",
        site_type: null,
        locale: null,
        available_locales: [],
        chat_gpt_model: null,
        dalle_model: "dall-e-3",
        use_ai: true,
        has_shop: false,
        has_blog: false,
        has_services: false,
        has_iframe: false,
        theme_color: {
          header: null,
          footer: null,
          first_btn: null,
          second_btn: null,
          other_elements: null,
        },
        contacts_information: {
          addresses: {},
        },
      });
      closeModal()
      return;
    }

    setIsLoading(true);
    const data = await dispatch(createWhitePage(options));
    if (data) {
      setCommonData({
        iframe_src: "",
        currency_label: '',
        goods_quantity: 3,
        posts_quantity: 3
      })
      setBuild({
        name: "",
        theme: null,
        topic: "",
        domain_name: "",
        site_type: null,
        locale: null,
        available_locales: [],
        chat_gpt_model: null,
        dalle_model: "dall-e-3",
        use_ai: true,
        has_shop: false,
        has_blog: false,
        has_services: false,
        has_iframe: false,
        theme_color: {
          header: null,
          footer: null,
          first_btn: null,
          second_btn: null,
          other_elements: null,
        },
        contacts_information: {
          addresses: {
            uk_UA: "",
            en_US: "",
            ru_RU: "",
          },
        },
      });
    }
    setIsLoading(false);
  };

  const handleClickGeneratePromt = async () => {
    setIsLoadingPrompt(true);
    const options = {
      prompt: build.topic,
      chat_gpt_model: build.chat_gpt_model,
    };
    const response = await dispatch(generatePrompt(options));

    if (response?.data?.extended_prompt) {
      setBuild({ ...build, topic: response?.data?.extended_prompt });
    }

    setIsLoadingPrompt(false);
  };

  const isDisabledButton = () => {
    if (build?.name?.length === 0) return true;
    else if (build?.domain_name?.length === 0) return true;
    else if (build?.locale === null) return true;
    else if (build?.chat_gpt_model === null) return true;
    else if (build?.dalle_model === null) return true;
    else if (build?.theme === null) return true;
    else if (build?.site_type === null) return true;
    else if (build?.topic.length === 0) return true;
    else if (build?.available_locales.length === 0) return true;
    else if (build?.theme_color.footer === null) return true;
    else if (build?.theme_color.first_btn === null) return true;
    else if (build?.theme_color.second_btn === null) return true;
    else if (build?.theme_color.header === null) return true;
    else if (build?.theme_color.other_elements === null) return true;
    else if (build?.has_iframe && commonData?.iframe_src?.length === 0) return true;
    else if (build?.has_shop && commonData?.currency_label?.length === 0) return true;
    else if (build?.has_shop && commonData?.goods_quantity?.length === 0) return true;
    else if (build?.has_blog && commonData?.posts_quantity?.length === 0) return true;
    return false;
  };

  const isDisabledButtonPrompt = () => {
    if (build.chat_gpt_model === null) return true;
    else if (build.topic.length === 0) return true;
    return false;
  };

  return (
    <div className="p-4 w-100 pb-0">
      <Row>
        <Col xs={4}>
          <UIFormInput
            onChange={handleChangeInput}
            type="text"
            id="name"
            label={"Название архива"}
            value={build?.name ? build?.name : ""}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "theme")}
            title={"Тема"}
            options={themes}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.theme}
            value={build?.theme}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "site_type")}
            title={"Тип страницы"}
            options={optionsTypePage}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.site_type}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <UIFormInput
            onChange={handleChangeInput}
            type="text"
            id="tag_id"
            label={"Google Ads Tag ID"}
            value={build?.tag_id ? build?.tag_id : ""}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "locale")}
            title={"Основной язык"}
            options={locales}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.locale}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "chat_gpt_model")}
            title={"Версия чата GPT"}
            options={gptModels}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.chat_gpt_model}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <UIFormInput
            onChange={handleChangeInput}
            type="text"
            id="domain_name"
            label={"Название домена"}
            value={build?.domain_name ? build?.domain_name : ""}
          />
        </Col>
        <Col xs={4}>
          <SelectSearchMulti
            onChange={(e) => handleChangeSelect(e, "available_locales")}
            title={"Доступные языки вайта"}
            options={locales}
            itemLabel={"label"}
            itemValue={"value"}
            values={build?.available_locales}
            default={build?.available_locales}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "dalle_model")}
            title={"Версия Dall-e"}
            options={dalle}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.dalle_model}
          />
        </Col>
      </Row>
      {build?.available_locales?.length !== 0 && (
        <Row>

          {build?.available_locales.map((item) => (
              <Col xs={4}>
                <UIFormInput
                    onChange={handleChangeInputAddress}
                    type="text"
                    id={item}
                    label={`Адрес ${item}`}
                    value={
                      build?.contacts_information?.addresses[item]
                          ? build?.contacts_information?.addresses[item]
                          : ""
                    }
                />
              </Col>

          ))}
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <UIFormTextaria
            onChange={handleChangeInput}
            type="text"
            className="input-textaria"
            id="topic"
            label={"Промт сайта"}
            value={build?.topic ? build?.topic : ""}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "header")}
              title={"Header Color"}
              options={headersColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.header}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "first_btn")}
              title={"First Btn Color"}
              options={btnColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.first_btn}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "second_btn")}
              title={"Second Btn Color"}
              options={btnColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.second_btn}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "footer")}
              title={"Footer Color"}
              options={footerColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.footer}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "other_elements")}
              title={"Other Element"}
              options={otherColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.other_elements}
          />
        </Col>
      </Row>
      <div className='d-flex gap-3 align-items-center mt-2'>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_shop: !build.has_shop})}
              id="has_shop"
              label="Has shop"
              className="ml-1"
              checked={build?.has_shop ? build?.has_shop : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_blog: !build.has_blog})}
              id="has_blog"
              label="Has blog"
              className="ml-1"
              checked={build?.has_blog ? build?.has_blog : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_services: !build.has_services})}
              id="has_services"
              label="Has services"
              className="ml-1"
              checked={build?.has_services ? build?.has_services : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_iframe: !build.has_iframe})}
              id="has_iframe"
              label="Has iframe"
              className="ml-1"
              checked={build?.has_iframe ? build?.has_iframe : false}
          />
        </div>
      </div>
      <Row>
        {build?.has_iframe && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="text"
              id="iframe_src"
              label={"Iframe src"}
              value={commonData?.iframe_src ? commonData?.iframe_src : ""}
          />
        </Col>}
        {build?.has_shop && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="text"
              id="currency_label"
              label={"Currency label"}
              value={commonData?.currency_label ? commonData?.currency_label : ""}
          />
        </Col>}
        {build?.has_shop && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="number"
              max={12}
              id="goods_quantity"
              label={"Goods quantity"}
              min={3}
              value={commonData?.goods_quantity ? commonData?.goods_quantity : 0}
          />
        </Col>}
        {build?.has_blog && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="number"
              id="posts_quantity"
              label={"Posts quantity"}
              min={3}
              max={6}
              value={commonData?.posts_quantity ? commonData?.posts_quantity : ""}
          />
        </Col>}
        </Row>

      {/* <Row>
        <Col className="d-flex flex-column mt-3 gap-1" xs={4}>
          <Col xs={12}>
            <Form.Check
              onChange={handleChangeCheckbox}
              id="use_ai"
              label="Использовать ИИ"
              className="ml-1"
              checked={build?.use_ai ? build?.use_ai : false}
            />
          </Col>
        </Col>
      </Row> */}
      <div className="d-flex mt-3 gap-2">
        <div className="ml-1 d-flex gap-2">
          <Button
            onClick={handleClickButton}
            disabled={isDisabledButton()}
            variant="primary"
            style={{ width: "142px", height: "38px" }}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Cгенерировать"
            )}
          </Button>
        </div>
        <div className="ml-1 d-flex gap-2">
          <Button
            onClick={handleClickGeneratePromt}
            disabled={isDisabledButtonPrompt()}
            variant="primary"
            style={isLoadingPrompt ? { width: "193px", height: "38px" } : {}}
          >
            {isLoadingPrompt ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Cгенерировать промт"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneratorWhitePage;
