/* eslint-disable */
import {vaultService} from "../../core/services/vault-service";
import docCookies from "../../core/services/cookie";
import {API} from "../../core/api";
import {GET_USER, USER_LOGOUT} from "./types";
import {helperError} from "../../helpers/helperError";

const service = vaultService();

const clearServicesData = () => {
    docCookies.clearAll();
    service.removeItems('role');
};

const setUserDataToLocalStorage = (accessToken, role, user) => {
    docCookies.setItem('token', accessToken);
    service.setItem('role', role);
    service.setItem('user', user);
    service.removeItems('copy-rules');
};



export const getUser = () => async () => {
    try {
        const response = await API.get("admin/users/me");
        service.setItem('user', response?.data);
    } catch (e) {
        helperError(e);
    }
};

export const loginByEmail = (email, password) => async (dispatch) => {
    try {
        const authenticationResponse = await API.post('auth/login', {
            email,
            password,
        });
        setUserDataToLocalStorage(
            authenticationResponse.data.access_token,
            authenticationResponse.data.user?.roles[authenticationResponse?.data?.user?.roles.length - 1],
            authenticationResponse.data.user,
        );
        dispatch({
            type: GET_USER,
            payload: authenticationResponse.data.access_token,
        });

        return authenticationResponse;
    } catch (error) {
        helperError(error);
        return error;
    }
};

export const userLogout = () => async (dispatch) => {
    try {
        clearServicesData();
        dispatch({ type: USER_LOGOUT });
    } catch (error) {
        console.log(error);
    }
};
