import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "../core/helpers/routes";
import Login from "../pages/Login";
import docCookies from "../core/services/cookie";
import WhitePage from "../pages/WhitePage";
import Template from "../components/Templates";
import ShowWhitePage from "../pages/ShowWhitePage";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getUser} from "../pages/Login/actions";

export const AppRouter = () => {
  const token = docCookies.getItem("token");
  const user = localStorage.getItem('user');
  const dispatch = useDispatch();


  useEffect(() => {
    if(token && !user) {
      dispatch(getUser())
    }
  },[])

  return (
    <>
      {token === null ? (
        <BrowserRouter>
          <Routes>
            <Route
              path={ROUTES.index.main}
              element={<Navigate to={ROUTES.authorization.link} />}
            />
            <Route path={ROUTES.authorization.path} element={<Login />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route
              path={ROUTES.index.main}
              element={<Navigate to={ROUTES.page.path} />}
            />
            <Route
              path={ROUTES.page.path}
              element={
                <Template>
                  <WhitePage />
                </Template>
              }
            />
            <Route
              path={ROUTES.showWhitePage.path}
              element={
                <Template>
                  <ShowWhitePage />
                </Template>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};
